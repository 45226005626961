<template>
  <div>
    <v-container>
      <p>
        Determine a valid set of quantum numbers (<stemble-latex
          content="$n, \ell, m_\ell, m_s$"
        />) for an electron in the following orbitals:
      </p>
    </v-container>
    <v-simple-table>
      <tbody>
        <tr v-for="(question, index) in questions" :key="question">
          <td>
            {{ question }}
          </td>
          <td>
            <v-text-field v-model="inputs[`input${index + 1}`]" @keypress="checkInput($event)" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'WriteQuantumNumbers',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      questions: ['2p', '3d', '1s', '4p'],
    };
  },
  methods: {
    checkInput(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9, )(/+-]+$/.test(char)) {
        return true;
      }
      e.preventDefault();
    },
  },
};
</script>
